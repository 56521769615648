



















































import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import { Payment } from "@/models/payment.model";
import PaymentsService from "@/services/PaymentsService";

const paymentsNamespace = namespace("Payments");

@Component({ components: { Vuetable } })
export default class PaymentsView extends Vue {
  fields = [
    { title: "E-mail", name: "user.email" },
    {
      title: "Payment method",
      name: "payment_method",
      sortField: "payment_method",
    },
    { title: "Is paid", name: "is_paid", sortField: "is_paid" },
    {
      title: "Amount",
      name: "amount",
      sortField: "amount",
    },
    {
      title: "Mollie Status",
      name: "mollie_status",
      sortField: "mollie_status",
    },
    { title: "Created at", name: "created_at", sortField: "created_at" },
    { name: "actions", sortField: "Actions" },
  ];
  isLoading = false;

  @paymentsNamespace.Getter("all")
  payments!: Payment;

  @paymentsNamespace.Action("fetchAll")
  fetchAll!: () => Promise<Payment>;

  @paymentsNamespace.Action("delete")
  delete!: (id: string) => Promise<Payment>;

  @paymentsNamespace.Action("create")
  createPayment!: (filter: Payment) => Promise<Payment>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async doExport() {
    const response = await PaymentsService.export();
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "payments.xlsx";
    link.click();
    URL.revokeObjectURL(link.href);
  }

  addPayment() {
    this.$router.push({ name: "payments-detail-create" });
  }

  editPayment(payment: Payment) {
    this.$router.push({
      name: "payments-detail",
      params: { id: payment.id },
    });
  }

  async deletePayment(payment: Payment) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove this payment`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(payment.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
  }
}
